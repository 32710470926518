import { React, useEffect } from 'react';
import { useState } from 'react';
import { post } from '../../util/request';
import { get } from '../../util/request';

import { useHistory } from 'react-router';


import forge from 'node-forge';
import { DatePickerField } from '../../components/common/form';
import { useTranslation } from 'react-i18next';
import { REGEX } from '../../constants';

import './index.scss'
import Password from '../../components/page/password';
import Loading from '../../components/common/loading';



const ActivateAccount = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [isButton, setButton] = useState(true)
  const [passwordInconsistencyPW, setPasswordInconsistencyPW] = useState(false);
  const [usernamePassed, setUsernamePassed] = useState(false)
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true)
  const [loading, setLoading] = useState(false);;

  const LAND = () => {
    empty()
    if (isButton === false) {
      setButton(!isButton)
    }
  }
  const LOAN = () => {
    empty()
    if (isButton === true) {
      setButton(!isButton)
    }
  }

  const [ischeck, setcheck] = useState(true)
  const Client = () => {
    empty()
    if (ischeck === false) {
      setcheck(!ischeck)
    }
  }
  const Other = () => {
    empty()
    if (ischeck === true) {
      setcheck(!ischeck)
    }
  }

  const [isterms, setisterms] = useState(false)
  const terms = (val) => {
    if (val === false) {
      setisterms(true)
    } else {
      setisterms(false)
    }
  }


  const [islogin, seislogin] = useState(true)

  const Back = () => {
    history.push({
      pathname: '/',
    });
  }


  const empty = () => {
    setclientId('')
    setdateofBirth(null)
    setlastName('')
    setphone('')
    setvendorId('')
    setemail('')
    setusername('')
    setPassword('')
    setconfirmPassword('')
  }

  const [clientId, setclientId] = useState('');
  const clientIdChange = event => {
    setclientId(event.target.value);
  };

  const [dateofBirth, setdateofBirth] = useState(null)
  const dateofBirthChange = event => {
    setdateofBirth(event.target.value);
  };

  const [lastName, setlastName] = useState('')
  const lastNameChange = event => {
    setlastName(event.target.value);
  };

  const [phone, setphone] = useState('')
  const phoneChange = event => {
    setphone(event.target.value);
  };

  const [vendorId, setvendorId] = useState('')
  const vendorIdChange = event => {
    setvendorId(event.target.value);
  };

  const [email, setemail] = useState('')
  const emailChange = event => {
    setemail(event.target.value);
  };

  const [username, setusername] = useState('')
  const usernameChange = event => {
    setusername(event.target.value);
    if (!REGEX.useName.test(event.target.value)) {
      setUsernamePassed(false)
      setConfirmBtnDisabled(true)
    } else {
      setUsernamePassed(true)
      setConfirmBtnDisabled(false)
    }
  };

  const [password, setPassword] = useState('')
  // const passwordChange = event => {
  //   setPassword(event.target.value);
  // };
  const passwordChange = (value) => {
    setPassword(value);
    if (value !== confirmPassword) {
      setPasswordInconsistencyPW(true)
      setConfirmBtnDisabled(true)
    } else {
      setPasswordInconsistencyPW(false)
      setConfirmBtnDisabled(false)
    }
  };

  const [confirmPassword, setconfirmPassword] = useState('')
  const confirmPasswordChange = (value) => {
    setconfirmPassword(value)
    if (value !== password) {
      setPasswordInconsistencyPW(true)
      setConfirmBtnDisabled(true)
    } else {
      setPasswordInconsistencyPW(false)
      setConfirmBtnDisabled(false)
    }
  };

  const [key, setkey] = useState('')


  const [clientIdVerifyId, setclientIdVerifyId] = useState('')



  useEffect(() => {
    getkey();
  }, []);


  const postActivateAccount = async () => {
    setLoading(true)
    var pubencryptKey = key
    var message = ''
    if (ischeck) {
      message = clientId
    } else {
      message = vendorId
    }
    console.log(message)

    let rsaPublicKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----${pubencryptKey}-----END PUBLIC KEY-----`);
    let encrypted = rsaPublicKey.encrypt(message, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    console.log(forge.util.encode64(encrypted))

    // postActivateAccount();
    let params = {}
    var time = null;
    if (dateofBirth) {
      var year = dateofBirth.getFullYear();
      var month = dateofBirth.getMonth() + 1 < 10 ? "0" + (dateofBirth.getMonth() + 1) : dateofBirth.getMonth() + 1;
      var day = dateofBirth.getDate() < 10 ? "0" + dateofBirth.getDate() : dateofBirth.getDate();
      time = dateofBirth ? year + "/" + month + "/" + day : null;
    }


    if (ischeck) {
      if (isButton) {
        params = {
          clientType: 'CLIENT',
          clientID: forge.util.encode64(encrypted),
          activationType: 'CLIENT_ID',
          dob: time,
        }
      } else {
        params = {
          clientType: 'CLIENT',
          lastName: lastName,
          activationType: 'LASTNAME',
          dob: time,
          last4OfTel: phone,
        }
      }
    } else {
      params = {
        clientID: forge.util.encode64(encrypted),
        clientType: "BIZ",
        email: email,
      }
    }
    console.log(params)
    const data = await post('/auth/account/activation', params);
    setLoading(false)
    if (!data) {
      return
    }
    empty()
    console.log(data)
    if (data.clientIdVerifyId) {
      setclientIdVerifyId(data.clientIdVerifyId)
      seislogin(false)
    }
    // if (data) {
    //   var list = data.content
    //   for (var i = 0; i < list.length; i++) {
    //     list[i].checked = false
    //   }
    //   setListData(list);
    // }

  };
  const setup = async () => {
    if (password !== confirmPassword) {
      // alert('Password inconsistency')
      return
    }
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]\D|.*\D[0-9])(?=.*[\W_]\w|.*\w[\W_]).{10,20}$/;
    if (!REGEX.password.test(password)) {
      return
    }
    if (!REGEX.useName.test(username)) {
      return
    }
    // if (!isterms) {
    //   alert('Terms & conditions')
    //   return
    // }
    console.log(clientIdVerifyId, 'clientIdVerifyId')
    var pubencryptKey = key
    var message = password
    let rsaPublicKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----${pubencryptKey}-----END PUBLIC KEY-----`);
    let encrypted = rsaPublicKey.encrypt(message, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    console.log(forge.util.encode64(encrypted))
    setLoading(true)
    let setupdata = {
      clientIDVerifyId: clientIdVerifyId,
      deviceModel: "WEB",
      entry: "WEB",
      isChina: false,
      password: forge.util.encode64(encrypted),
      username: username,
      deviceId: "",
      deviceToken: "",
    }
    const data = await post('/auth/account/activation/setup', setupdata);
    setLoading(false)
    if (!data) {
      return
    }

    empty()
    if (data.code === 0) {
      // history.push({ pathname: '/loginInformation', })
      history.push({ pathname: '/loginTandC', })
      // seiDone(true)
    }

  };


  const getkey = async () => {
    let deviceId = {
      deviceId: 'Web'
    }
    const data = await get('/auth/key', deviceId);
    if (!data) {
      return
    }
    setkey(data.message)
    // encryptPassword(data.message, id)
  };

  const goto = () => {
    history.push({ pathname: '/loginTandC', })
  }

  const [isdone, seiDone] = useState(false)


  // const rsaEncrypt = async (pubencryptKey, message) => {
  //   pubencryptKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz6v9U622G0exFV62ipTIIDdK7ReQy5fgeFV9rgFQOuYQtcGXmA8q585f6JFnERaKl7Jk+CeIKBTzmp1teicfd6M5Gcm7mwWVpF6RJoV/CDGp+1JcZD20deoqwDmzyc9GUPxHIsB901Cy009maoKgicAd/UFSdDu242UcMapgvQJFsojPMhPHeeoqsWPL1BFdzO/1T20/mpaumtq8i+sHo/0fWdc5BLeUZJlnVX/lzc5y26q9MS/gWFEZmMJxpOwxRJ3hd4NLNlpjFAN+MDj78TNrDT5iy/m/3NkovFLzYZNAG84xzAht4QdYaaldmP2r76EE+eBjvZ9/UGF/r7FVawIDAQAB'
  //   const forge = await import("node-forge");
  //   const publicKeyAll =
  //     "-----BEGIN PUBLIC KEY-----\n" +
  //     pubencryptKey +
  //     "\n-----END PUBLIC KEY-----";
  //   var publicKey = forge.pki.publicKeyFromPem(publicKeyAll);
  //   var buffer = forge.util.createBuffer(message, "utf8");
  //   var bytes = buffer.getBytes();
  //   var pubencryptedText = forge.util.encode64(
  //     publicKey.encrypt(bytes, "RSA-OAEP", {
  //       md: forge.md.sha256.create(),
  //       mgf1: {
  //         md: forge.md.sha256.create(),
  //       },
  //     })
  //   );
  //   console.log(pubencryptedText)
  //   setclientId(pubencryptedText);
  //   return pubencryptedText;
  // }









  return (
    <div className='activateAccount'>
      {loading ? <Loading /> : null}
      <div className='d-flex justify-content-between align-items-center headline-box'>
        <div className='line'>
        </div>
        <h1 className='headline'>
          {t('Account Activation')}
        </h1>
        <div className='line'>
        </div>
      </div>
      <div className='main-box'>
        {isdone ?
          <div className='content-box-done'>
            <div className='content d-flex flex-column justify-content-center align-items-center'>

              <div className='m-b-32'>
                <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M47.896 21.4837C47.896 30.0354 40.9444 36.9673 32.3693 36.9673C23.7975 36.9591 16.8509 30.0322 16.8426 21.4837C16.8426 12.9324 23.7942 6 32.3693 6C40.9444 6 47.896 12.9324 47.896 21.4837ZM44.9582 21.4837C44.9582 14.5508 39.3223 8.92966 32.3693 8.92966C25.42 8.93791 19.7887 14.5536 19.7809 21.4837C19.7809 28.4174 25.4168 34.0376 32.3693 34.0376C39.3223 34.0376 44.9582 28.4174 44.9582 21.4837ZM72.7215 45.6249L60.0591 38.9013C59.6228 38.6641 59.0947 38.6696 58.6635 38.9155L47.0734 45.2438C42.8282 42.0943 37.7452 40.4539 32.3403 40.4539C25.6123 40.4685 19.3693 43.0762 14.7419 47.8222C10.0558 52.6122 7.48491 59.1603 7.50007 66.2648C7.50236 67.0732 8.15863 67.7276 8.96874 67.7299L49.8349 67.6566C51.7559 70.0353 54.2258 71.9149 57.0327 73.1354L58.7369 73.8823C58.922 73.9648 59.1227 74.0046 59.3248 73.9996C59.5259 73.9959 59.7253 73.9565 59.9122 73.8823L61.8364 73.0474C68.9065 70.0894 73.505 63.1882 73.4999 55.5418V46.9283C73.5004 46.3842 73.2014 45.8836 72.7215 45.6249ZM10.482 64.7856C10.7901 59.0284 13.0377 53.7699 16.8572 49.8584C20.9257 45.6835 26.4344 43.3835 32.3545 43.3835H32.3986C37.2612 43.3835 41.8151 44.9072 45.5901 47.7929V55.7177C45.5924 58.864 46.3795 61.9599 47.8813 64.7265L10.482 64.7856ZM60.6759 70.3523C66.6659 67.8568 70.5649 62.0158 70.5621 55.5418V47.7929L59.3831 41.8457L48.5279 47.7783V55.7035C48.5343 62.0968 52.3332 67.8801 58.2079 70.4397L59.3248 70.9234L60.6612 70.3523H60.6759ZM53.2871 54.6048C53.9039 54.0791 54.8311 54.151 55.3583 54.7656L57.7528 57.5784L64.3927 52.1876C65.0214 51.6738 65.9486 51.7654 66.4634 52.3923C66.9787 53.0198 66.8868 53.9444 66.2581 54.4583L58.5019 60.7421C58.2401 60.9537 57.9131 61.0673 57.5765 61.0645C57.1476 61.0632 56.7402 60.8763 56.4601 60.5516L53.1259 56.6698C52.5987 56.0552 52.6708 55.1306 53.2871 54.6048Z" fill="#003448" />
                </svg>
              </div>
              <dvi className='accountTitle'>
                {t('Activation Success')}
              </dvi>

              <div className='promote m-b-32'>{t('Your Account has been successfully activated, please log in directly')}</div>


              <div className=''>
                <button onClick={() => goto()} type="button" class="nextBtn btn btn-outline-next">{t("Done")}</button>
              </div>

            </div>

          </div> :
          <div className='content-box'>
            {islogin ?
              <div className='content'>
                <div className='promote m-b-32'>{t('Please enter the following information to verify your account.')}</div>
                <div className='usertype'>
                  <p>{t('User Type')}</p>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <button className={(ischeck ? 'radio radio-check' : 'radio')} onClick={() => Client()}>
                        <div className='check '></div>
                      </button>
                      {t('Client')}
                    </div>

                    <div className='d-flex align-items-center'>
                      <button className={(!ischeck ? 'radio radio-check' : 'radio')} onClick={() => Other()}>
                        <div className='check'></div>
                      </button>
                      {t('Business Partner')}
                    </div>
                  </div>
                </div>
                {ischeck ?
                  <div>
                    <div className='tabs d-flex align-items-center pointer userSelect'>
                      <div className={(isButton ? 'tabsName checked' : 'tabsName')} onClick={() => LAND()}>
                        <p >{t('Client ID')}</p>
                      </div>
                      <div className={(!isButton ? 'tabsName checked' : 'tabsName')} onClick={() => LOAN()}>
                        <p >{t('Last Name')}</p>
                      </div>
                    </div>
                    {isButton ?
                      <div className='clientid'>
                        <span>{t('Client ID')}</span>
                        <input value={clientId} onChange={clientIdChange} type="text" placeholder={t('Enter Client ID')} className='clientid-input' />
                        <span>{t('Date of Birth')}</span>
                        {/* <input value={dateofBirth} onChange={dateofBirthChange} type="text" placeholder="Your date of birth" className='clientid-input' /> */}
                        <div className='datainput'>
                          <DatePickerField value={dateofBirth} setValue={setdateofBirth} ></DatePickerField>
                        </div>
                      </div> :
                      <div className='clientid'>
                        <span>{t('Last Name')}</span>
                        <input value={lastName} onChange={lastNameChange} type="text" placeholder={t('Enter your last name')} className='clientid-input' />
                        <span>{t('Date of Birth')}</span>
                        {/* <input value={dateofBirth} onChange={dateofBirthChange} type="text" placeholder="Your date of birth" className='clientid-input' /> */}
                        <div className='datainput m-b-20'>
                          <DatePickerField value={dateofBirth} setValue={setdateofBirth}></DatePickerField>
                        </div>
                        <span>{t('Phone')}</span>
                        <input value={phone} onChange={phoneChange} type="text" placeholder={t("Last 4 digit of your mobile number")} className='clientid-input' />
                      </div>}
                  </div>
                  :
                  <div>
                    <div className='clientid'>
                      <span>{t('Vendor ID')}</span>
                      <input value={vendorId} onChange={vendorIdChange} type="text" placeholder={t('Enter Vendor ID')} className='clientid-input' />
                      <span>{t('Registered Email Address')}</span>
                      <input value={email} onChange={emailChange} type="text" placeholder={t('Enter registered email address')} className='clientid-input' />
                    </div>
                  </div>}
                <div className='d-flex flex-column justify-content-center'>
                  <button onClick={() => postActivateAccount()} type="button" class="nextBtn btn btn-outline-next">{t('NEXT')}</button>
                  <button onClick={() => Back()} type="button" class="btn btn-outline-link">{t('BACK')}</button>
                </div>

              </div>
              :
              <div className='content'>
                <div className='promote m-b-32'>{t('Please create your username and password for the Walton app login.')}</div>

                <div className='clientid'>
                  <div className="usename-box">
                    <span>{t("Username")}</span>
                    <input value={username} onChange={usernameChange} type="text" placeholder={t("Enter your username")} className='clientid-input' maxLength={64} />

                    {username && <p className={`${usernamePassed ? 'green' : ''} error-message m-b-20`} dangerouslySetInnerHTML={{ __html: t('UserNameMessage') }}></p>}
                  </div>
                  {/* <span>{t("Password")}</span>
                  <input value={password} onChange={passwordChange} type="password" placeholder={t("Enter password")} className='clientid-input' /> */}
                  <Password onChange={passwordChange} label={t("Password")} placeholder={t("Enter password")} />
                  {/* <span>{t("Confirm Password")}</span> */}
                  {/* <input value={confirmPassword} onChange={confirmPasswordChange} type="password" placeholder={t("Confirm Password")} className='clientid-input' /> */}
                  <Password onChange={confirmPasswordChange} label={t("Confirm Password")} placeholder={t("Confirm Password")} inconsistencyPW={passwordInconsistencyPW} />
                </div>

                {/* <div className='Terms'>
                        {isterms ?
                          <div className='pointer' onClick={() => terms(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="12" fill="#5582A0" />
                              <path d="M18.7219 8.31595C18.3373 7.91041 17.6961 7.89255 17.2899 8.27813L10.8231 14.411L7.73857 11.2441C7.34791 10.8433 6.70738 10.8345 6.30623 11.2252C5.90542 11.6156 5.89698 12.2568 6.2873 12.6576L10.0691 16.5403C10.2673 16.7439 10.5306 16.8466 10.7947 16.8466C11.0453 16.8466 11.2958 16.754 11.492 16.5687L18.6841 9.748C19.0899 9.36305 19.1071 8.72184 18.7219 8.31595Z" fill="white" />
                            </svg>
                          </div> :
                          <div className='pointer' onClick={() => terms(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="12" fill="#5582A0" />
                            </svg>
                          </div>
        
                        }
        
                        <p>Terms & conditions</p>
                      </div> */}

                <div className='d-flex flex-column justify-content-center'>
                  <button onClick={() => setup()} type="button" class={`${confirmBtnDisabled ? 'bg-disabled' : ''} nextBtn btn btn-outline-next`} disabled={confirmBtnDisabled ? true : false}>{t('CONFIRM')}</button>
                  <button onClick={() => Back()} type="button" class="btn btn-outline-link">{t('BACK')}</button>
                </div>

              </div>
            }


          </div>
        }



      </div>
    </div>
  );
};
export default ActivateAccount;
