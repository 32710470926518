
import { configureStore } from '@reduxjs/toolkit'
import { counterSlice } from './slice'

// 调用方法
const store = configureStore({
    reducer: {
        counter: counterSlice.reducer
    },
})


export default store