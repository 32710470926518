let routerRef = null;

const routerRefFunc = {
  setRef: (ref) => {
    routerRef = ref;
  },
  getRef: () => {
    return routerRef;
  },
  pushSkipUrl: (url) => {
    routerRef.history.push(url);
  },
  replaceSkip: (url) => {
    routerRef.history.replace(url);
  },
  goBack: () => {
    routerRef.history.goBack();
  },
};

export { routerRefFunc };
