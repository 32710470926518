import React, { useState, useEffect } from 'react';
import { get } from '../../util/request';
import { post } from '../../util/request';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo, updateUserInfoFun } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import './index.scss'
import { set } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';





const InboxDetails = () => {
  const { t } = useTranslation();
  const user = useSelector(userInfo);
  const history = useHistory();
  const { id } = useParams();

  const location = useLocation();
  const [detailDate, setDetailDate] = useState(null);
  const [img, setImg] = useState('');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState('');
  const [button1, setButton1] = useState({});
  const [button2, setButton2] = useState({});
  const init = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
      id: id
    };
    const data = await get(`/notification/details/${id}`, params);
    // const data = await get('/notification/details', params);
    if (!data) {
      return
    }
    setDetailDate(data)
    setImg(data.imagePath)
    setContent(data.content)
    setTitle(data.title)
    setType(data.type)
    setButton1({
      type: data.button1Type,
      name: data.button1Name,
      url: data.button1Url
    })
    setButton2({
      type: data.button2Type,
      name: data.button2Name,
      url: data.button2Url
    })
    var time = data.deliveryDate
    // var month = ''
    if (time.slice(5, 7) == '01') {
      month = 'Jan'
    } else if (time.slice(5, 7) == '02') {
      month = 'Feb'
    }
    else if (time.slice(5, 7) == '03') {
      month = 'Mar'
    }
    else if (time.slice(5, 7) == '04') {
      month = 'Apr'
    }
    else if (time.slice(5, 7) == '05') {
      month = 'May'
    }
    else if (time.slice(5, 7) == '06') {
      month = 'Jun'
    }
    else if (time.slice(5, 7) == '07') {
      month = 'Jul'
    }
    else if (time.slice(5, 7) == '08') {
      month = 'Aug'
    }
    else if (time.slice(5, 7) == '09') {
      month = 'Sept'
    }
    else if (time.slice(5, 7) == '10') {
      month = 'Oct'
    }
    else if (time.slice(5, 7) == '11') {
      month = 'Nov'
    }
    else if (time.slice(5, 7) == '12') {
      month = 'Dec'
    }
    var day = time.slice(8, 10)
    var year = time.slice(0, 4)
    var month = time.slice(5, 7)
    // setDate(month + ' ' + day + ', ' + year)
    setDate(year + '/' + month + '/' + day)
  };

  const goto = () => {

    history.push({
      pathname: '/inbox',
    });
  }

  useEffect(() => {
    init();
  }, [location]);


  return (
    <div className='inboxDetails'>
      {/* <div className='inbox-img' style={{ backgroundImage: `url(${img})` }}>
        <img className='img-fluid' src={img} alt="" />
      </div> */}
      <div className='main-box padding-main'>
        <div className='headline  d-flex align-items-center'>
          <div className='back pointer d-flex' onClick={goto}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16.1939 18.3047C16.3142 18.425 16.377 18.5777 16.377 18.7488C16.377 18.9198 16.3142 19.0726 16.1939 19.1929C16.0739 19.3129 15.9225 19.376 15.7534 19.376C15.5844 19.376 15.433 19.3129 15.313 19.1929L8.56004 12.44C8.44005 12.32 8.37695 12.1685 8.37695 11.9995C8.37695 11.8305 8.44005 11.6791 8.56004 11.5591L15.3016 4.81828C15.363 4.74816 15.438 4.69582 15.5242 4.6641C15.5981 4.63684 15.675 4.62305 15.7534 4.62305C15.8318 4.62305 15.9087 4.63684 15.9827 4.6641C16.0688 4.69582 16.1439 4.74816 16.1939 4.80613C16.3139 4.92613 16.377 5.07755 16.377 5.24658C16.377 5.41561 16.3139 5.56704 16.194 5.68693L9.88861 11.9994L16.1939 18.3047Z" fill="#5582A0" />
            </svg>
            <p>{t('Inbox')}</p>
          </div>
        </div>
        <div className='list-card'>
          <div className='label'>
            <span>
              {type}
            </span>
          </div>
          <div className='content'>
            <p>{title}</p>
          </div>
          <div className='time'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 1.33398C4.32345 1.33398 1.33301 4.32443 1.33301 8.00065C1.33301 11.6769 4.32345 14.6673 7.99967 14.6673C11.6759 14.6673 14.6663 11.6769 14.6663 8.00065C14.6663 4.32443 11.6759 1.33398 7.99967 1.33398ZM7.99967 13.6345C4.89355 13.6345 2.36579 11.1068 2.36579 8.00065C2.36579 4.89453 4.89355 2.36677 7.99967 2.36677C11.1063 2.36677 13.6336 4.89453 13.6336 8.00065C13.6336 11.1068 11.1058 13.6345 7.99967 13.6345ZM8.51605 7.78375L10.3751 9.17802C10.6033 9.34896 10.6498 9.67273 10.4783 9.90049C10.3771 10.0368 10.2222 10.1076 10.0647 10.1076C9.95678 10.1076 9.84832 10.074 9.75538 10.0043L7.6898 8.4551C7.55967 8.35802 7.48324 8.20464 7.48324 8.04198V4.94359C7.48324 4.65802 7.71408 4.42719 7.99965 4.42719C8.28522 4.42719 8.51605 4.65802 8.51605 4.94359V7.78375Z" fill="#5582A0" />
            </svg>
            <span>{date}</span>
          </div>
        </div>
        <div className='detailsContent'>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <div className="button-box">
            {
              button1.name &&
              <button type="button" className="submit btn btn-outline-next" onClick={() => {
                if (button1.type == 'URL') {
                  window.open(button1.url);
                } else {
                  if (user.sfUserType != 'CL') {
                    return
                  }
                  history.push({
                    pathname: '/Investments',
                  });
                }
              }}>
                {button1.name}
              </button>
            }
            {button2.name &&
              <button type="button" className="submit btn btn-outline-next" onClick={() => {
                if (button2.type == 'URL') {
                  window.open(button2.url);
                } else {
                  if (user.sfUserType != 'CL') {
                    return
                  }
                  history.push({
                    pathname: '/Investments',
                  });
                }
              }}>
                {button2.name}
              </button>
            }
          </div>
        </div>

      </div>
    </div>
  );
};
export default InboxDetails;
