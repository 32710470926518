import React from 'react';
import { useState } from 'react';
import './index.scss'

import { useSelector } from 'react-redux';
import { userInfo } from '../redux/slice';

import { useHistory } from 'react-router';
import {
  useLocation
} from "react-router-dom";



// const [collapsed, setCollapsed] = useState(false);

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const user = useSelector(userInfo);
  const history = useHistory();

  function changePage(url) {
    history.push(url);
  }

  const logoutMenu = (<></>
    // <Menu
    //   items={[
    //     {
    //       label: '0rd menu item',
    //       key: '0',
    //     },
    //     {
    //       label: '1rd menu item',
    //       key: '1',
    //     },
    //     {
    //       label: '2rd menu item',
    //       key: '3',
    //     },
    //   ]}
    // />
  );
  
  let location = useLocation();
  console.log(location)
  return (
    <div className="base">{children}</div>
  )
};

export default MainLayout;