import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss'
const Password = ({ onChange, label, placeholder, inconsistencyPW }) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [localValue, setLocalValue] = useState('');
    const [lenghtError, setLenghtError] = useState('PasswordRules1');
    const [specialCharError, setSpecialCharError] = useState('PasswordRules2');
    const [lenghVerificationPassed, setLenghVerificationPassed] = useState(false)
    const [specialCharVerificationPassed, setSpecialCharVerificationPassed] = useState(false)
    const validateDigit = (val) => {
        if (!/^.{10,20}$/.test(val)) {
            setLenghVerificationPassed(false)
        } else {
            setLenghVerificationPassed(true)
        }
    };
    const validateNoSpecialChars = (val) => {
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?~`])(?=\S+$)/.test(val)) {
            setSpecialCharVerificationPassed(false)
        } else {
            setSpecialCharVerificationPassed(true)
        }
    };
    const handleChange = (event) => {
        setLocalValue(event.target.value);
        validateDigit(event.target.value);
        validateNoSpecialChars(event.target.value);
        onChange(event.target.value)
        // onChange(localValue, lenghVerificationPassed, specialCharVerificationPassed)
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const inputType = showPassword ? 'text' : 'password';
    return (
        <>
            <span>{label}</span>
            <div className="password-input">
                <input value={localValue} onChange={handleChange} type={inputType} placeholder={placeholder} className='clientid-input' maxLength={20} />
                <button className="change-btn f-c-title" onClick={togglePasswordVisibility}>
                    <i className={`${showPassword ? 'icon-eye' : 'icon-close-eye'} eye`}></i>
                </button>
            </div>
            {(localValue && inconsistencyPW) ?
                <div className="error-message">{t('PasswordInconsistency')}</div> :
                localValue && <div className='m-b-20'>
                    <div>
                        <i></i><span className={`${lenghVerificationPassed ? 'green' : ''} error-message`}>{t(lenghtError)}</span>
                    </div>
                    <div>
                        <i></i><span className={`${specialCharVerificationPassed ? 'green' : ''} error-message`}>{t(specialCharError)}</span>
                    </div>
                </div>
            }

        </>
    )
}
export default Password;