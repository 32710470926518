import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.scss';

const SubmitSuccessPopUp = ({ isModalOpen, title, content, handleOk, handleCancel, icon, confirmText = 'Done' }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer=""
                centered
                width={1000}
                classNames={{ body: 'custom-modal-body' }}
            >
                <div className="modal-box">
                    <p>
                        <i className={`${icon ? icon : 'icon-history'} icon-box`}></i>
                    </p>
                    <div className="text-title">{title}</div>
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: content }}></p>
                    <div className="custom-modal-body confirm-btn">
                        <button
                            type="button"
                            class="nextBtn"
                            onClick={handleOk}
                        >
                            {t(confirmText)}
                        </button>
                    </div>
                </div>

            </Modal>
        </>
    )
}
export default SubmitSuccessPopUp