import React, { useState, useEffect } from 'react';
import { get } from '../../util/request';
import { post } from '../../util/request';
import { put } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo, updateUserInfoFun } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import './index.scss'
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className='policy'>
      <div className='main-box'>
        <div className='headline'>
          <p>{t('Privacy')}</p>
        </div>
        <div className='box'>
          <h4 className='tible' style={{ margin: '12px 0' }}>{t('PRIVACY POLICY')}</h4>
          <h4 className='tible' style={{ margin: '0' }} >{t('CLIENT PRIVACY POLICY  COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION')}</h4>
          <h4 className='tible' style={{ margin: '12px 0' }} >{t('SCOPE AND APPLICATION OF POLICY')}</h4>
          <p className='content'>
            {t('At Walton Group and our related entities (“Walton Group”), we are committed to protecting and maintaining the accuracy, confidentiality and security of your personal information as a client of Walton. This Client Privacy Policy describes the personal information Walton collects from or about you, how we use that information, and to whom and in what circumstances we disclose that information.')}
          </p>
          <h4 className='tible'>{t('PRIVACY OFFICER')}</h4>
          <p className='content'>
            {t('In order to ensure compliance with this policy and applicable privacy legislation, Walton has appointed a Privacy Officer. Walton’s Privacy Officer will also address and evaluate complaints to the policy on a casebycase basis. If you have any questions or complaints about the application of this policy, please contact Walton’s Privacy Officer')}
          </p>
          <ul>
            <li>
              <p className='content'>
                - {t('Ms. Clara Chong')}
              </p>
              <p className='content'>
                - {t('Privacy Officer')}
              </p>
              <p className='content'>
                - {t('Walton Group of Companies')}
              </p>
              <p className='content'>
                - {t('Address 25th Floor, 500 – 4th Avenue SW, Suite 2500, Calgary, Alberta T2P 2V6')}
              </p>
              <p className='content'>
                - {t('Direct Telephone (403) 7502295')}
              </p>
              <p className='content'>
                - {t('Confidential Fax (403) 2613918')}
              </p>
              <p className='content'>
                - {t('Email cchong@walton.com')}
              </p>
            </li>
          </ul>
          <h4 className='tible'>{t('COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION')}</h4>
          <p className='content'>
            {t('For purposes of this Client Privacy Policy, personal information means any information about an identifiable individual, other than the person’s business title or business contact information when collected, used or disclosed in the course of commercial activity, to conduct business with you or provide services or products to you.')}
          </p>
          <p className='content'>
            {t('The type of information we collect about you includes the following')}
            :
          </p>
          <ul>
            <li>
              <p className='content'>
                - {t('Information such as your name, home address, telephone number and email address')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Social Insurance Number, identity card number, or other government identification number')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Copies of any agreements you enter into with us')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Information that you provide to us, such as your preferences, identities of your advisors and decisionmakers, feedback and comments')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Credit and financial information')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Verifying your identity in order to grant website access')}
              </p>
            </li>
          </ul>

          <p className='content'>
            {t('Walton uses the personal information above for different purposes relating to the management of our business and our relationship with you. These purposes include, but are not limited to the following')}
            :
          </p>

          <ul>
            <li>
              <p className='content'>
                - {t('Establishing, maintaining and managing a business relationship with you so that we may provide you with services or products that you have requested')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Enabling us to comply with your requests such as preferred method of communication')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Sharing your personal information with a party representing you, such as your investment representative, financial planner or to other third parties at your direction/consent')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Enabling us to review the products and services that we provide to you so that we may understand your requirements for our products and services and improve our products and services')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Sharing your personal information within Walton Group of Companies for purposes of contacting you in your role as an investor, including informing you of investment opportunities')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Protecting our business from error, fraud, theft and damage to our goods and property')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Enabling us to comply with applicable law or regulatory requirements (including tax requirements)')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Safeguarding and protecting Walton’s confidential information')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Other purposes reasonably required for the management of our business relationship with you and the management of Walton’s business')}
              </p>
            </li>
          </ul>
          <p className='content'>
            {t('Walton shares your personal information with its employees, auditors, contractors and consultants and other parties, including its subsidiaries and affiliated companies (the “Walton Group”) who require such information to assist us with administering our business relationship with you. These include third parties that provide services to us or on our behalf, third parties that collaborate with Walton in the provision of services to you and third parties with whom we contract to perform our services.')}
          </p>
          <p className='content'>
            {t('In addition, your personal information may be disclosed or transferred to another party (including to another member of the Walton Group or an affiliate of Walton) in the event of a change in ownership of, or a grant of a security interest in, all or part of Walton through a sale transaction or some other form of business combination, merger or joint venture. We will disclose personal information in these circumstances only when the third parties agree to use such personal information solely for the purposes evaluating and carrying out the transaction and, with respect to that information, to act in manner consistent with the principles articulated in this Client Privacy Policy.')}
          </p>
          <p className='content'>
            {t('Walton may contract with third parties located in one or more countries outside of Canada, including potentially in the United States of America or the European Union, to provide Walton with processing, storage or other administrative or datarelated services. Please be advised that in that case your information may be transferred to, processed, used and stored in those countries, and that the governments, courts or law enforcement or regulatory agencies located in those countries may be able to obtain disclosure of your information through the laws of, or pursuant to a lawful order made in, those countries.')}
          </p>

          <h4 className='tible'>{t('CONSENT')}</h4>

          <p className='content'>
            {t('We require your consent to collect, use and disclose your personal information in accordance with this Policy. Consent may be expressed or implied. Express consent may be given orally or in writing, including electronic transmission. Implied consent is consent that can be reasonably inferred by your action or inaction. By providing us with the personal information described in this Policy, we assume you have impliedly provided Walton with your consent to collect, use and disclose your personal information in accordance with this Policy.')}
          </p>
          <p className='content'>
            {t('You may withdraw or limit your consent to Walton’s collection, use and disclosure of your personal information at any time, subject to your legal and contractual obligations you may have to us as a result of our business relationship. You must provide reasonable notice of the withdrawal of your consent. All communications with respect to the withdrawal or limitation of your consent should be directed to our Privacy Officer in writing.')}
          </p>
          <p className='content'>
            {t('In some circumstances, Walton is entitled, or required, to disclose your personal information without your consent. These circumstances include, but are not limited to')}
            :
          </p>
          <ul>
            <li>
              <p className='content'>
                - {t('Protecting your health or safety')}
              </p>
              <p className='content'>
                - {t('The disclosure is necessary for an investigation or proceeding')}
              </p>
              <p className='content'>
                - {t('The disclosure is required or authorized by law')}
              </p>
              <p className='content'>
                - {t('The disclosure is required to comply with a subpoena, warrant or order of a court or other body authorized to compel production of the information')}
              </p>
              <p className='content'>
                - {t('The disclosure is to a public body and relates to an investigation of an offence')}
              </p>
              <p className='content'>
                - {t('The disclosure is to a lawyer who is representing Walton')}
              </p>
              <p className='content'>
                - {t('The disclosure is required to respond to an emergency')}
              </p>
              <p className='content'>
                - {t('Where the personal information is publicly available')}
              </p>
            </li>
          </ul>
          <h4 className='tible'>{t('ACCURACY AND RETENTION OF PERSONAL INFORMATION')}</h4>

          <p className='content'>
            {t('Walton endeavors to ensure that any personal information in its possession is as accurate, current and complete as necessary for the purposes for which we use that information. If your personal information changes or you believe information we have about you is not accurate, please notify us as soon as reasonably possible. Any request to verify or correct your personal information must be made in writing to Walton’s Privacy Officer. In some circumstances, we may not agree to your request to change your personal information, but will instead append an alternative text to the record in question.')}
          </p>

          <p className='content'>
            {t('We keep your personal information only as long as it is required for the reasons it was collected. The length of time we retain information varies, depending on the product or service and the nature of the information. This period may extend beyond the end of your business relationship with us but it will only be for as long as it is necessary for us to have sufficient information to respond to any issues that may arise at a later date and to comply with applicable law and regulatory requirements. When we no longer require your personal information, we will either destroy or erase it, or we will make it anonymous such that it cannot be associated with or tracked to you.')}
          </p>
          <h4 className='tible'>{t('SAFEGUARDING PERSONAL INFORMATION')}</h4>
          <p className='content'>
            {t('Walton is committed to protecting the security of your personal information. We endeavor to maintain physical, technical and procedural safeguards that are appropriate to the sensitivity of the personal information in question. These safeguards are designed to prevent your personal information from loss and unauthorized access, copying, use, modification or disclosure.')}
          </p>
          <h4 className='tible'>{t('ACCESS TO YOUR PERSONAL INFORMATION')}</h4>

          <p className='content'>
            {t('You may ask to see the personal information we have about you. A request to review your personal information must be made in writing to Walton’s Privacy Officer. Walton will endeavor to provide the information requested within a reasonable time from the date of the written request, or within the time otherwise required by law.')}
          </p>
          <p className='content'>
            {t('If you make a request to review your personal information, we may request specific information from you to enable us to confirm your identity and right to access such information. We may also require information that would assist us in locating your personal information. We may charge a fee to access your personal information; if so, we will notify you of that fee at the time you make your request or within a short time thereafter.')}
          </p>
          <p className='content'>
            {t('Walton reserves the right to decline to provide access to personal information in certain circumstances. These include, but are not limited to, circumstances where the information requested')}
          </p>
          <ul>
            <li>
              <p className='content'>
                - {t('Would disclose personal information, including opinions, about another individual')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Would disclose Walton’s trade secrets or other confidential business information')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Is subject to lawyer-client privilege')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Is not readily retrievable and the burden or cost of providing it would be disproportionate to the value of the information')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Does not exist or cannot be found')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('Could result in serious harm to the individual requesting it, serious harm to the life, safety or health of another person, or serious emotional harm or embarrassment to another individual')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('May harm or interfere with law enforcement activities and other investigative or regulatory functions of a body authorized by statute to perform such functions')}
              </p>
            </li>
            <li>
              <p className='content'>
                - {t('May be or is required to be withheld by other legislation')}
              </p>
            </li>
          </ul>

          <p className='content'>
            {t('Where information requested will not or cannot be disclosed, Walton will provide the reasons for non-disclosure.')}
          </p>
          <h4 className='tible'>{t('COOKIE POLICY')}</h4>
          <p className='content'>
            {t('Walton uses cookies and similar technologies such as JavaScript and HTML (“cookies”) on its websites to facilitate and improve your online experience.')}
          </p>

          <h4 className='tible'>{t('HOW WE USE COOKIES')}</h4>

          <p className='content'>
            {t('When you visit Walton.com, your web browser will automatically download cookies to your device. Cookies allow for faster loading of website content and allows our site to help you navigate efficiently to obtain the information and services you have requested. Cookies also allow our site to remember your preferences such as language selection and help you to view information that is most relevant to your interests. Walton’s third party content manager server uses cookies can to store session data so you can leave Walton’s website, come back, and remain logged in.')}
          </p>

          <p className='content'>
            {t('Walton uses a platform called Eloqua to send out information via email and to help manage and track email consent statuses in compliance with Canada’s Anti-spam Laws. When you visit an Eloqua landing page or a Walton webpage that has Eloqua tracking scripts on it, Walton, through Eloqua, uses the cookies to count website visits, email opens, email link clicks, and unsubscribe requests.')}
          </p>

          <h4 className='tible'>{t('REVISIONS TO THIS POLICY')}</h4>


          <p className='content'>
            {t('Walton may amend this Client Privacy Policy from time to time to reflect changes in its legal or regulatory obligations or in the manner in which we deal with your personal information. We will post any revised version of this Policy on our website at www.Walton.com. We encourage you to review the website on a regular basis. Any changes to this Policy will be effective as of the date posted on the website.')}
          </p>

          <h4 className='tible'>{t('INTERPRETATION OF THIS POLICY')}</h4>


          <p className='content'>
            {t('The Privacy Officer has the responsibility and authority to interpret and administer this Policy. This Policy does not create or confer any additional rights or obligations to those imposed under provincial or federal privacy legislation. Should there be, in a specific case, any inconsistency between this Policy and applicable provincial or federal privacy legislation, then this Policy will be interpreted to give effect to and comply with such privacy laws.')}
          </p>


        </div>
      </div>


    </div>
  );
};
export default PrivacyPolicy;
