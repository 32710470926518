import React from 'react';
import { useState } from 'react';
import { post } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo, updateUserInfoFun } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import './index.scss'


const LoginInformation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isButton, setButton] = useState(true)

  const goto = () => {
    history.push({
      pathname: '/',
    });
  }




  return (
    <div className='loginInformation'>
      <div className='d-flex justify-content-between align-items-center headline-box'>
        <div className='line'>
        </div>
        <h1 className='headline'>
          {t('Activation Success')}
        </h1>
        <div className='line'>
        </div>
      </div>
      <div className='main-box'>
        <div className='content-box'>
          <div className='content d-flex flex-column justify-content-center align-items-center'>

            <div className='m-b-32'>
              <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.896 21.4837C47.896 30.0354 40.9444 36.9673 32.3693 36.9673C23.7975 36.9591 16.8509 30.0322 16.8426 21.4837C16.8426 12.9324 23.7942 6 32.3693 6C40.9444 6 47.896 12.9324 47.896 21.4837ZM44.9582 21.4837C44.9582 14.5508 39.3223 8.92966 32.3693 8.92966C25.42 8.93791 19.7887 14.5536 19.7809 21.4837C19.7809 28.4174 25.4168 34.0376 32.3693 34.0376C39.3223 34.0376 44.9582 28.4174 44.9582 21.4837ZM72.7215 45.6249L60.0591 38.9013C59.6228 38.6641 59.0947 38.6696 58.6635 38.9155L47.0734 45.2438C42.8282 42.0943 37.7452 40.4539 32.3403 40.4539C25.6123 40.4685 19.3693 43.0762 14.7419 47.8222C10.0558 52.6122 7.48491 59.1603 7.50007 66.2648C7.50236 67.0732 8.15863 67.7276 8.96874 67.7299L49.8349 67.6566C51.7559 70.0353 54.2258 71.9149 57.0327 73.1354L58.7369 73.8823C58.922 73.9648 59.1227 74.0046 59.3248 73.9996C59.5259 73.9959 59.7253 73.9565 59.9122 73.8823L61.8364 73.0474C68.9065 70.0894 73.505 63.1882 73.4999 55.5418V46.9283C73.5004 46.3842 73.2014 45.8836 72.7215 45.6249ZM10.482 64.7856C10.7901 59.0284 13.0377 53.7699 16.8572 49.8584C20.9257 45.6835 26.4344 43.3835 32.3545 43.3835H32.3986C37.2612 43.3835 41.8151 44.9072 45.5901 47.7929V55.7177C45.5924 58.864 46.3795 61.9599 47.8813 64.7265L10.482 64.7856ZM60.6759 70.3523C66.6659 67.8568 70.5649 62.0158 70.5621 55.5418V47.7929L59.3831 41.8457L48.5279 47.7783V55.7035C48.5343 62.0968 52.3332 67.8801 58.2079 70.4397L59.3248 70.9234L60.6612 70.3523H60.6759ZM53.2871 54.6048C53.9039 54.0791 54.8311 54.151 55.3583 54.7656L57.7528 57.5784L64.3927 52.1876C65.0214 51.6738 65.9486 51.7654 66.4634 52.3923C66.9787 53.0198 66.8868 53.9444 66.2581 54.4583L58.5019 60.7421C58.2401 60.9537 57.9131 61.0673 57.5765 61.0645C57.1476 61.0632 56.7402 60.8763 56.4601 60.5516L53.1259 56.6698C52.5987 56.0552 52.6708 55.1306 53.2871 54.6048Z" fill="#003448" />
              </svg>
            </div>
            <dvi className='accountTitle'>
              {t('Activation Success')}
            </dvi>

            <div className='promote m-b-32'>{t('Your Account has been successfully activated, please log in directly')}</div>


            <div className='next-btn'>
              <button onClick={() => goto()} type="button" class="nextBtn btn btn-outline-next">{t("Done")}</button>
            </div>

          </div>

        </div>

      </div>
    </div>
  );
};
export default LoginInformation;
