import React from 'react';
import { Route, Switch } from 'react-router';
import { ROUTER_TABLE } from './comm';
import MainLayout from '../layouts/MainLayout';
import BaseLayout from '../layouts/BaseLayout';

const RootRouter = () => {
  return (
    <>
      <Switch>
        {ROUTER_TABLE.map(({ component: Component, auth, ...others }, i) => {
          return <Route key={`route_${i}`} exact render={() => {
            if (auth == true) {
              return <BaseLayout><Component /></BaseLayout>
            } else {
              return <MainLayout><Component /></MainLayout>
            }
          }} {...others} />;
        })}
      </Switch>
    </>
  );
};

export { RootRouter };
