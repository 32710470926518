import React from 'react';
import { useEffect } from 'react';
import './index.scss'
import { login } from '../../util/request';
import { updateUserInfo } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Qs from 'qs';
import ExitProjects from './exitProjects/index.js'
import LandAndLoanInvestments from './landAndLoanInvestments/index.js'
import ExitBanner from '../../components/page/exitBanner/index.js'

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.search;
  var obj = Qs.parse(locationState, { ignoreQueryPrefix: true });
  const accessToken = obj.accessToken;
  const objectId = obj.objectId;


  const handleLogin = async (accessToken, objectId) => {
    const obj = {
      accessToken: accessToken,
      objectId: objectId,
      deviceId: null,
      deviceModel: 'WEB',
      deviceToken: null,
      entry: 'WEB',
      isChina: false,
    };
    const data = await login('/auth/aad/b2c/login', obj);
    if (data) {
      dispatch(updateUserInfo(data));
      if (data.sfUserType == "CL") {
        goDetail('/home');
      } else {
        goDetail('/viewClient');
      }
    }
  };
  function goDetail(url) {
    history.push({
      pathname: url
    });
  }

  useEffect(() => {
    if (accessToken) {
      handleLogin(accessToken, objectId);
      localStorage.removeItem('banner_status');
    }
  }, []);

  return (
    <>
      <div className='main-box home-exit-banner'>
        {!accessToken ? (<>
          <div className='row'>
            <ExitBanner />
          </div>
        </>) : ("")}
      </div>
      <div className='home main-box' >
        <div className='portfolio-view row'>
          <h2>
            PORTFOLIO VIEW
          </h2>
        </div>
        {!accessToken ? (<>
          <div className='home-section row'>
            <ExitProjects />
          </div>
          <div className='home-section row'>
            <LandAndLoanInvestments />
          </div>
        </>) : ("")}
      </div>
    </>
  );
};
export default Home;
