import React from 'react';
import './index.scss'


// const [collapsed, setCollapsed] = useState(false);

const BaseLayout = ({ children }) => {
  return (
    <div className="base">{children}</div>
  )
};

export default BaseLayout;
