import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_US from './json/en-us.json';
 import jp from './json/jp.json';
import zh_HK from './json/zh-hk.json';
import zh_CN from './json/zh-cn.json';

export const resources = {
  en_US: {
    translation: en_US,
  },
  zh_HK: {
    translation: zh_HK,
  },
  zh_CN: {
    translation: zh_CN,
  },
  jp: {
    translation: jp,
  },
};
i18n
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    keySeparator: '.',
    fallbackLng: 'en_US',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: 'lang',
    },
  });

export default i18n;
