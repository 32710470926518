import React from 'react';
import './index.scss';
// import { post } from '../../util/request';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// // import { Button, Row, Input, Form } from 'antd'
// import { useDispatch } from 'react-redux';
// import {
//   updateUserInfo,
// } from '../../redux/slice';

// const FormItem = Form.Item
const Foot = () => {
  const history = useHistory();
  const { t } = useTranslation();

  function goDetail(url) {
    console.log(url);
    history.push({
      pathname: url,
    });
  }
  return (
    <div className="foot ">
      <div className='main-box contact-box'>
        <div className='d-flex contact-us'>
          <div className='contact' onClick={() => goDetail('/contactUs')}>{t("CONTACT US")}</div>
          <div className='connect'>
            {t("CONNECT")}
            <div className='instagram-box d-flex align-items-center'>
              <div className='instagram' onClick={() => { window.open('https://www.linkedin.com/company/walton-global/') }}>in</div>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="36" height="27" viewBox="0 0 36 27" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1191 23.9697C34.3951 22.4323 35.8497 20.1603 35.8497 17.6336C35.8497 13.0061 31.0155 9.25368 25.0538 9.25368C19.0921 9.25368 14.2579 13.0061 14.2579 17.6336C14.2579 22.2625 19.0921 26.0149 25.0538 26.0149C26.2859 26.0149 27.4746 25.8522 28.5783 25.5558L28.8949 25.5113C29.1025 25.5113 29.2906 25.5711 29.4683 25.6657L31.8324 26.9374L32.04 27C32.2386 27 32.3999 26.8497 32.3999 26.6647L32.3417 26.4198L31.8548 24.7294L31.8175 24.5151C31.8175 24.2897 31.937 24.0907 32.1191 23.9697ZM12.9632 0C5.80822 0 0.0078125 4.50232 0.0078125 10.0579C0.0078125 13.0882 1.75212 15.8166 4.48357 17.6601C4.7031 17.8048 4.84647 18.0441 4.84647 18.3154L4.80166 18.5714L4.21774 20.5999L4.14755 20.8935C4.14755 21.1161 4.34169 21.297 4.57914 21.297L4.83004 21.2218L7.66603 19.6956C7.87809 19.5815 8.1036 19.5105 8.35299 19.5105L8.73382 19.5634C10.057 19.9182 11.4847 20.1157 12.9632 20.1157L13.674 20.099C13.3933 19.3143 13.2394 18.4879 13.2394 17.635C13.2394 12.5692 18.5291 8.46202 25.0538 8.46202L25.7572 8.47872C24.782 3.67448 19.4251 0 12.9632 0ZM21.4547 16.2938C20.6602 16.2938 20.0165 15.6927 20.0165 14.9525C20.0165 14.211 20.6602 13.6113 21.4547 13.6113C22.2507 13.6113 22.8943 14.211 22.8943 14.9525C22.8943 15.6927 22.2507 16.2938 21.4547 16.2938ZM28.6529 16.2938C27.8569 16.2938 27.2133 15.6927 27.2133 14.9525C27.2133 14.211 27.8569 13.6113 28.6529 13.6113C29.4474 13.6113 30.0911 14.211 30.0911 14.9525C30.0911 15.6927 29.4474 16.2938 28.6529 16.2938ZM8.64421 8.44811C7.68992 8.44811 6.91783 7.7274 6.91783 6.83974C6.91783 5.95069 7.68992 5.22998 8.64421 5.22998C9.5985 5.22998 10.3721 5.95069 10.3721 6.83974C10.3721 7.7274 9.5985 8.44811 8.64421 8.44811ZM17.2806 8.44811C16.3263 8.44811 15.5542 7.7274 15.5542 6.83974C15.5542 5.95069 16.3263 5.22998 17.2806 5.22998C18.2349 5.22998 19.0085 5.95069 19.0085 6.83974C19.0085 7.7274 18.2349 8.44811 17.2806 8.44811Z" fill="white" />
              </svg> */}
            </div>

          </div>

          <div className='locations' onClick={() => { window.open('https://walton.com/contact-us/#office-locations') }}>
            {t("LOCATIONS")}
            <div className='d-flex flex-wrap'>
              <div className='list-before'>
                {t("SCOTTSDALE")}
              </div>
              <div className='list-before'>
                {t("TAMPA")}
              </div>
              <div className='list-before'>
                {t("CALGARY")}
              </div>
              <div className='list-before'>
                {t("SHANGHAI")}
              </div>
              <div className='list-before'>
                {t("GUANGZHOU")}
              </div>
              <div className='list-before'>
                {t("HONG KONG")}
              </div>
              <div className='list-before'>
                {t("TAIPEI")}
              </div>
              <div className='list-before'>
                {t("SINGAPORE")}
              </div>
              <div className='list-before'>
                {t("DUBAI")}
              </div>

            </div>
          </div>
        </div>
        <div className='disclaimer'>
          <div className='llc'>© 2024 Walton Global Holdings, LLC</div>
          <div className='details'>{t("about us")}</div>
          <div className='d-flex'>
            <div className='tersmofuse' onClick={() => { goDetail('/TandC') }}>{t("TERMS OF USE")}</div>
            <div className='tersmofuse' onClick={() => { goDetail('/PrivacyPolicy') }}> {t("PRIVACY")}</div>
            {/* <div className='tersmofuse'>Sitemap</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Foot;
