const NFT_STATUS_ENUM = {
  OFFERED: {
    status: 1,
    text: 'Placed bid',
  },
  NOT_SUCCESS: {
    status: 2,
    text: 'Not Success',
  },
  SUCCESS: {
    status: 3,
    text: 'Success',
  },
  PAID: {
    status: 4,
    text: 'Paid',
  },
  TIMEOUT: {
    status: 5,
    text: 'Not Success',
  },
};

const REGEX = {
  price: /^(\d+)[.]?\d?$/,
  walletAddress: '^[a-zA-Z0-9]*$',
  onlyNumber: /^[0-9]+$/,
  // The length is between 8-64,Must begin with alphabetical character or number, and can only contain alphanumeric characters and the following symbols: - or _
  useName: /^[a-zA-Z0-9][a-zA-Z0-9_-]{7,63}$/,
  // 你的密码必须包含10到20个字符，并且至少包含1个大写字母、1个小写字母、1个符号和1个数字
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?~`])(?=\S+$).{10,20}$/,
  // password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&].{10,20}$/
  email: /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/

};

export { NFT_STATUS_ENUM, REGEX };
