import './index.scss'
import { useState, useEffect } from 'react'
import { get } from '../../../util/request';
import { userInfo } from '../../../redux/slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default ExitProjects; function ExitProjects() {
    const user = useSelector(userInfo);
    const { t } = useTranslation();
    const [exitProcessHeader, setExitProcessHeader] = useState([]);
    const history = useHistory();

    const getExitProcessHeader = async () => {
        let params = {
            accessToken: user.accessToken,
            userId: user.userId,
            accountNum: user.clientID
        }
        const data = await get('home/exitProjects', params);
        setExitProcessHeader(data);
    }

    const formatNumber = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    const formatDate = (value) =>
        new Intl.DateTimeFormat('fr-CA', {
            year: 'numeric', month: '2-digit', day: '2-digit'

        }).format(value).replace(/-/g, '/');

    function goDetail(url, recId) {
        history.push({
            pathname: url,
            state: { recId: recId }
        });
        sessionStorage.setItem('recId', recId);
    }


    useEffect(() => {
        getExitProcessHeader();
    }, []);

    return (<>{
        exitProcessHeader.length > 0 ? (<>
            <div className='exit-projects'>
                <div className='row'>
                    <p className='left-border home-title'>
                        {t('Exit Offer')}
                    </p>
                </div>
                <div className='row'>
                    {
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {t('Product')}
                                    </th>
                                    <th>
                                        {t('Description')}
                                    </th>
                                    <th>
                                        {t('Net Multiple')}
                                    </th>
                                    <th>
                                        {t('Net Offer Price')}
                                    </th>
                                    <th>
                                        {t('Total Exit Acre')}
                                    </th>
                                    <th>
                                        {t('Voting Deadline')}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            {exitProcessHeader?.map((exitHeader) => (
                                <>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='offer-text'>
                                                    {exitHeader.productName}
                                                </div>
                                                <div className='offer-sub-text'>
                                                    {exitHeader.location}
                                                </div>
                                            </td>
                                            <td>
                                                {exitHeader.name}
                                            </td>
                                            <td>
                                                {exitHeader.netMultiple.toUpperCase().replace("X", "") + "X"}
                                            </td>
                                            <td>
                                                {formatNumber(exitHeader.netOfferPrice)}
                                            </td>
                                            <td>
                                                {exitHeader.totalExitAcre}
                                            </td>
                                            <td>
                                                {formatDate(new Date(exitHeader.votingDeadline))}
                                            </td>
                                            <td>
                                                <button className='exit-vote-button' onClick={
                                                    (event) => {
                                                        if (!user.accessToken) {
                                                            event.preventDefault();
                                                        } else {
                                                            goDetail('/exitvoting', exitHeader.recid);
                                                        }
                                                    }}>
                                                    <svg className='check-exit-vote-button' width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_6893_12614)">
                                                            <path d="M13.1595 4.81579C12.9313 5.07079 12.7166 5.32579 12.5153 5.58079C12.4213 5.68816 12.3408 5.79553 12.2468 5.91632C12.4079 6.41289 12.5018 6.94974 12.5018 7.5C12.4884 10.3184 10.1934 12.6134 7.375 12.6134C4.55658 12.6134 2.26158 10.3184 2.26158 7.5C2.26158 4.68158 4.55658 2.38658 7.375 2.38658C8.27421 2.38658 9.13316 2.62816 9.87132 3.03079C10.1263 2.77579 10.3813 2.53421 10.6497 2.29263L10.8242 2.145C9.83105 1.50079 8.65 1.125 7.375 1.125C3.85868 1.125 1 3.98368 1 7.5C1 11.0163 3.85868 13.875 7.375 13.875C10.8913 13.875 13.75 11.0163 13.75 7.5C13.75 6.54711 13.5353 5.63447 13.1595 4.81579Z" fill="#003448" />
                                                            <path d="M8.90617 5.87148C8.39459 6.49674 7.91144 7.13622 7.4567 7.7899C7.22933 8.11674 7.00196 8.45779 6.7888 8.79885L4.96986 6.55358C4.7567 6.29779 4.38722 6.24095 4.10301 6.42569C3.80459 6.62464 3.73354 7.02253 3.93249 7.32095L6.22038 10.7173C6.27722 10.8025 6.36249 10.8878 6.46196 10.9446C6.58986 11.0299 6.73196 11.0725 6.87407 11.0725C7.14407 11.0725 7.44249 10.9304 7.57038 10.6888C7.69828 10.4615 8.33775 9.282 8.76407 8.64253C9.17617 8.00306 9.60249 7.37779 10.0714 6.76674C10.5262 6.15569 11.0093 5.55885 11.5209 4.99043C12.0183 4.422 12.5583 3.83937 13.0983 3.342L13.1125 3.32779C13.3683 3.08622 13.3825 2.68832 13.1551 2.43253C12.9135 2.16253 12.5156 2.13411 12.2456 2.37569C11.6204 2.9299 11.0662 3.48411 10.512 4.06674C9.94354 4.64937 9.40354 5.24622 8.90617 5.87148Z" fill="#003448" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_6893_12614">
                                                                <rect width="13.5" height="13.5" fill="white" transform="translate(0.25 0.75)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    {t('Vote')}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </>
                            ))}
                        </table>
                    }
                </div>
            </div>
        </>) : ""
    } </>)
}
