import { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
// import Header from './components/page/header';
import { RootRouter } from './routes';
import { routerRefFunc } from './util/routerRef';
import Header from './components/page/header/index'
import Footer from './components/page/footer/index'
import { useHistory } from 'react-router';
import { FloatButton } from 'antd';
import {
  UpOutlined
} from '@ant-design/icons';

function App() {
  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动效果
  }
  useEffect(() => {
    window.addEventListener('hashchange', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }, []);

  return (
    <div className="app-container">
      <Router
        ref={(ref) => {
          routerRefFunc.setRef(ref);
        }}
      >
        <Header />
        <RootRouter />
        <Footer />
        <FloatButton shape="circle" icon={<UpOutlined />} onClick={() => scrollTop()} />
      </Router>
    </div>
  );
}

export default App;
