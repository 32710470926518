import React from 'react';
import { Image } from '../../assets';
import './page.scss';
const NoDataBox = ({ msg }) => {
  return (
    <div className="no-data-box">
      <div className="icon-box">
        <img src={Image.emptyIcon} alt="no data icon" />
      </div>
      <p className="f-s-20">{msg}</p>
    </div>
  );
};
export default NoDataBox;
