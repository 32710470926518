import React from 'react';
import { useState, useEffect } from 'react';
import { post, get } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Pagination } from 'antd';
import './index.scss';
import Select from 'react-select';
import NoDataBox from '../../components/page/noData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import code from '../../assets/dialCode.json';
import SubmitSuccessPopUp from '../../components/page/submitSuccessPopUp';
import { REGEX } from '../../constants';

const Investments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const user = useSelector(userInfo);

  const [isButton, setButton] = useState(0);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [totalActiveHolding, setTotalActiveHolding] = useState({});
  const [totalActiveLoanHolding, setTotalActiveLoanHolding] = useState({});

  const [country, setcountry] = useState([]);

  //
  const [offices, setOffices] = useState([]);
  const [clientServices, setClientServices] = useState([]);
  const [isSearchable, setIsSearchable] = useState(false);
  const [searchDocType, setsearchDocType] = useState(null);
  const [title, setTitle] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneHeader, setPhoneHeader] = useState('+86');
  const [lang, setLang] = useState(null);
  const [countryRegion, setCountryRegion] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [contactMethod, setContactMethod] = useState(null);
  const [error, setError] = useState({
    title: null,
    firstName: null,
    lastName: null,
    phoneHeader: null,
    phoneNumber: null,
    lang: null,
    countryRegion: null,
    submitMessage: null,
    email: null,
    contactMethod: null,
  })
  const ENQUIRYFORM = () => {
    setButton(0);
  };
  const OFFICELOCATIONS = () => {
    setButton(1);
  };
  const CLIENTSERVICES = () => {
    setButton(2);
  };

  const officeslist = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };

    const data = await get('/contactUs/offices', params);
    if (data) {
      setOffices(data);
      console.log(data);
    }
  };

  const countrylist = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };

    const data = await get('/country', params);
    if (data) {
      // var Container = [
      //   'value'
      // ]
      // for (let i = 0; i < data.length; i++) {
      //   Container.push(value[i]=data.countryId[i],label[i]=data.name[i])
      //   console.log(Container)
      // }

      let namedata = JSON.parse(JSON.stringify(data).replaceAll('name', 'label'));
      let iddata = JSON.parse(JSON.stringify(namedata).replaceAll('countryId', 'value'));
      setcountry(iddata);
    }
  };

  const clientServiceslist = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };

    const data = await get('/contactUs/clientServices', params);
    if (data) {
      setClientServices(data);
      console.log(data);
    }
  };

  const [username, setusername] = useState('');
  const usernameChange = (event) => {
    setusername(event.target.value);
  };

  const init = async (tab) => {

  };
  const submitApi = async () => {
    const countryId = country.find(item => item?.label == countryRegion)?.value
    const contactMethodValue = contactMethodOptions.find(item => item?.label == contactMethod)?.value
    const langValue = options.find(item => item?.label == lang)?.value

    let params = {
      contactMethod: contactMethodValue,
      content: submitMessage,
      countryId: countryId,
      dialCode: phoneHeader,
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      preferredLang: langValue,
      title: title
    };
    const data = await post('/enquiry', params);
    if (data) {
      setIsModalOpen(true)
    }
  };
  const submitHandle = () => {
    if (firstName && lastName && title && phoneHeader && phoneNumber && lang && countryRegion && submitMessage && email && contactMethod) {
      if (!REGEX.email.test(email)) {
        setError({ ...error, email: true })
        return
      } else {
        setError({ ...error, email: false })
      }
      submitApi()
    } else {
      setError({
        ...error,
        title: title ? false : true,
        firstName: firstName ? false : true,
        lastName: lastName ? false : true,
        phoneHeader: phoneHeader ? false : true,
        phoneNumber: phoneNumber ? false : true,
        lang: lang ? false : true,
        countryRegion: countryRegion ? false : true,
        submitMessage: submitMessage ? false : true,
        email: REGEX.email.test(email) ? false : true,
        contactMethod: contactMethod ? false : true,
      })
    }
  }

  const options = [
    { value: 'en_US', label: t('English') },
    { value: 'ja', label: t('Japanese') },
    { value: 'zh_CN', label: t('SimplifiedChinese') },
    { value: 'zh_HK', label: t('TraditionalChinese') },
  ];

  const titleOptions = [
    { value: t('Mr'), label: t('Mr') },
    { value: t('Mrs'), label: t('Mrs') },
    { value: t('Ms'), label: t('Ms') },
    { value: t('Miss'), label: t('Miss') },
    { value: t('Dr'), label: t('Dr') },
  ];
  const contactMethodOptions = [
    { value: 'email', label: t('Email') },
    { value: 'phone', label: t('Phone') },
  ]

  const [phone, setPhone] = useState([]);
  const phonelist = () => {
    var data = [];
    for (let i = 0; i < code.length; i++) {
      data.push({ label: '+' + code[i].dial, value: '+' + code[i].dial });
    }
    setPhone(data);
  };
  function goDetail(url) {
    console.log(url);
    history.push({
      pathname: url,
    });
  }

  const handleOk = () => {
    setTitle(null)
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setCountryRegion(null);
    setSubmitMessage("");
    setPhoneHeader('+86');
    setLang("");
    setIsModalOpen(false);
    setEmail("")
    setContactMethod(null)
    init();
  };
  useEffect(() => {
    countrylist();
    init();
    officeslist();
    clientServiceslist();
    phonelist();
  }, []);
  return (
    <div className="investments">
      <SubmitSuccessPopUp isModalOpen={isModalOpen} title={t("EnquirySubmitted")} content={t("EnquirySubmittedDescription")} icon="icon-sent" handleOk={handleOk} handleCancel={handleOk} />
      {contextHolder}
      <div className="main-box">
        <div className="headline">
          <p>{t('Contact Us')}</p>
        </div>
        <div className="tabs d-flex align-items-center pointer userSelect">
          <div
            className={isButton == 0 ? 'tabsName checked' : 'tabsName'}
            onClick={() => ENQUIRYFORM()}
          >
            <p>{t('ENQUIRY FORM')} </p>
          </div>
          <div
            className={isButton == 1 ? 'tabsName checked' : 'tabsName'}
            onClick={() => OFFICELOCATIONS()}
          >
            <p>{t('OFFICE LOCATIONS')}</p>
          </div>
          <div
            className={isButton == 2 ? 'tabsName checked' : 'tabsName'}
            onClick={() => CLIENTSERVICES()}
          >
            <p>{t('CLIENT SERVICES')}</p>
          </div>
        </div>
        {isButton == 0 ? (
          <div className="tabsContent">
            <p className="enquiries">
              {t('Your questions are important to us. Please fill out the form below and we will get back to you within 2 business days. Review our')}<span style={{ textDecoration: 'underline' }} onClick={() => goDetail('/PrivacyPolicy')}>{t('Privacy Policy.')}</span>.
            </p>
            <div className="Required">*{t('Required Fields')}</div>
            <div className="from">
              <Container className="Container">
                <Row>
                  <Col md={2}>
                    <p className="fromtitle">{t('Title')}*</p>
                    <Select
                      isSearchable={isSearchable}
                      options={titleOptions}
                      value={{ value: title, label: title }}
                      defaultValue={title}
                      onChange={(data) => {
                        setTitle(data.value);
                        if (data.value) {
                          setError({ ...error, title: false })
                        } else {
                          setError({ ...error, title: true })
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: '#F5F7FA',
                          primary: '#003448',
                        },
                      })}
                    />
                    {
                      error.title && (
                        <span className="error-message">{t("Please select")}</span>
                      )}
                  </Col>
                  <Col xs={6} md={5} className="m-t-26">
                    <p className="fromtitle">{t('First Name')}*</p>
                    <input
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        if (e.target.value) {
                          setError({ ...error, firstName: false })
                        } else {
                          setError({ ...error, firstName: true })
                        }
                      }}
                      type="text"
                      placeholder={t('Enter your first name')}
                      className="clientid-input"
                      maxLength={50}
                    />
                    {
                      error.firstName && (
                        <span className="error-message">{t("Required Fields")}</span>
                      )}
                  </Col>
                  <Col xs={6} md={5} className="m-t-26">
                    <p className="fromtitle">{t('Last name')}*</p>
                    <input
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        if (e.target.value) {
                          setError({ ...error, lastName: false })
                        } else {
                          setError({ ...error, lastName: true })
                        }
                      }}
                      type="text"
                      placeholder={t('Enter your last name')}
                      className="clientid-input"
                      maxLength={50}
                    />
                    {
                      error.lastName && (
                        <span className="error-message">{t("Required Fields")}</span>
                      )}
                  </Col>
                </Row>
              </Container>
              <Container className="Container">
                <Row>
                  <Col xs={4} md={2}>
                    <p className="fromtitle">{t('Phone')}*</p>
                    <Select
                      isSearchable={isSearchable}
                      options={phone}
                      defaultValue={phone[0]}
                      value={{ value: phoneHeader, label: phoneHeader }}
                      onChange={(data) => {
                        setPhoneHeader(data.value);
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: '#F5F7FA',
                          primary: '#003448',
                        },
                      })}
                    />
                    {
                      error.phone && (
                        <span className="error-message">{t("Please select")}</span>
                      )}
                  </Col>
                  <Col xs={8} md={5}>
                    <p className="fromtitle fromtitleNone">-</p>
                    <input
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        if (e.target.value) {
                          setError({ ...error, phoneNumber: false })
                        } else {
                          setError({ ...error, phoneNumber: true })
                        }
                      }}
                      type="text"
                      placeholder={t('Enter your number')}
                      className="clientid-input"
                      maxLength={15}
                    />
                    {
                      error.phoneNumber && (
                        <span className="error-message">{t("Required Fields")}</span>
                      )}
                  </Col>
                  <Col md={5} className="m-t-26">
                    <p className="fromtitle">{t('Email address')}*</p>
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value) {
                          if (!REGEX.email.test(e.target.value)) {
                            setError({ ...error, email: true })
                          } else {
                            setError({ ...error, email: false })
                          }
                        } else {
                          setError({ ...error, email: true })
                        }
                      }}
                      type="email"
                      placeholder={t('Enter your email address')}
                      className="clientid-input"
                      maxLength={50}
                    />
                    {
                      error.email && (
                        <span className="error-message">{t("Required Fields")}</span>
                      )}
                  </Col>
                </Row>
              </Container>
              <Container className="Container">
                <Row>
                  <Col md={6}>
                    <p className="fromtitle">{t('Preferred language')}*</p>
                    <Select
                      isSearchable={isSearchable}
                      options={options}
                      value={{ value: lang, label: lang }}
                      onChange={(data) => {
                        setLang(data.label);
                        if (data.label) {
                          setError({ ...error, lang: false })
                        } else {
                          setError({ ...error, lang: true })
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: '#F5F7FA',
                          primary: '#003448',
                        },
                      })}
                    />
                    {
                      error.lang && (
                        <span className="error-message">{t("Please select")}</span>
                      )}
                  </Col>
                  <Col md={6} className="m-t-26">
                    <p className="fromtitle">{t('Country or Region')}* </p>
                    <Select
                      isSearchable={true}
                      value={{ value: countryRegion, label: countryRegion }}
                      options={country}
                      defaultValue={country[0]}
                      onChange={(data) => {
                        setCountryRegion(data.label);
                        if (data.label) {
                          setError({ ...error, countryRegion: false })
                        } else {
                          setError({ ...error, countryRegion: true })
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: '#F5F7FA',
                          primary: '#003448',
                        },
                      })}
                    />
                    {
                      error.countryRegion && (
                        <span className="error-message">{t("Please select")}</span>
                      )}
                  </Col>
                </Row>
              </Container>
              <Container className="Container">
                <Col md={6}>
                  <p className="fromtitle">{t('Preferred contact method')}*</p>
                  <Select
                    isSearchable={isSearchable}
                    placeholder={t("Please select")}
                    options={contactMethodOptions}
                    value={{ value: contactMethod, label: contactMethod }}
                    onChange={(data) => {
                      setContactMethod(data.label);
                      if (data.label) {
                        setError({ ...error, contactMethod: false })
                      } else {
                        setError({ ...error, contactMethod: true })
                      }
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: '#F5F7FA',
                        primary: '#003448',
                      },
                    })}
                  />
                  {
                    error.contactMethod && (
                      <span className="error-message">{t("Please select")}</span>
                    )}
                </Col>
              </Container>
              <Container className="Container">
                <Row>
                  <p className="fromtitle">{t('Message')}*</p>
                  <Col sm={12}>
                    <input
                      value={submitMessage}
                      onChange={(e) => {
                        setSubmitMessage(e.target.value);
                        if (e.target.value) {
                          setError({ ...error, submitMessage: false })
                        } else {
                          setError({ ...error, submitMessage: true })
                        }
                      }}
                      type="text"
                      placeholder={t('Enter your message')}
                      className="clientid-input"
                      maxLength={500}
                    />
                    {
                      error.submitMessage && (
                        <span className="error-message">{t("Required Fields")}</span>
                      )}
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="enquiry">
              <button type="button" className="submit btn btn-outline-next" onClick={() => { submitHandle() }}>
                {t('SUBMIT')}
              </button>
            </div>
          </div>
        ) : isButton == 1 ? (
          <div className="tabsContent">
            <div className=" container table-body">
              {offices.length > 0 ? (
                <div>
                  <Table className="table-border  align-middle">
                    <thead className="mobile-thead">
                      <tr>
                        <th>{t('Country/Region')}</th>
                        <th>{t('City')}</th>
                        <th>{t('Address')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {offices.map((item) => {
                        return (
                          <tr>
                            <td className="country-text">{item.country}</td>
                            <td className="city-text">{item.city}</td>
                            <td className="address-text"><i className="icon-location"></i><span>{item.address}</span></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoDataBox msg={'No Data'} />
              )}
              {/* <div className='container'>
                <div >
                  <FooterPagination pageNum={pageNum} pageSize={pageSize}></FooterPagination>
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="tabsContent">
            <div className=" container table-body">
              {clientServices.length > 0 ? (
                <div>
                  <Table className="table-border  align-middle">
                    <thead className="mobile-thead">
                      <tr>
                        <th>{t('Location')}</th>
                        <th>{t('Hotlines')}</th>
                        <th>{t('Email')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientServices.map((item) => {
                        return (
                          <tr>
                            <td>{item.country}</td>
                            <td className="phoneNumber-text"><i className="icon-phone"></i><span>{item.phoneNumber}</span></td>
                            <td className="email-text"><i className="icon-email"></i><span>{item.email}</span></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoDataBox msg={'No Data'} />
              )}
              {/* <div className='container'>
                <div >
                  <FooterPagination pageNum={pageNum} pageSize={pageSize}></FooterPagination>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Investments;
