import './index.scss';
import { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import { useRef } from 'react'
import { userInfo } from '../../../../redux/slice';
import { useSelector } from 'react-redux';
import { post } from '../../../../util/request.js';
import { Box, Button } from '@mui/material';
import Loading from '../../../../components/common/loading.js';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default ExitSummary; function ExitSummary({ VotingDetails, VotingResult }) {
    const user = useSelector(userInfo);
    const signatureCanvasRef = useRef(null);
    const [confirmButtonStatus, setConfirmButtonStatus] = useState(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    function goDetail(url) {
        history.push({
            pathname: url
        });
    }
    const handleClear = () => {
        signatureCanvasRef.current.clear();
        setConfirmButtonStatus(true);
    };

    const handleEnd = () => {
        setConfirmButtonStatus(signatureCanvasRef.current.isEmpty());
    }

    const handleConfirm = async () => {
        if (!signatureCanvasRef.current.isEmpty()) {
            setLoading(true);
            let params = {
                accessToken: user.accessToken,
                userId: user.userId,
                headerRecId: VotingDetails.recId,
                accountNumber: user.clientID
            }

            params.signatureImage = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png').split(',')[1];
            params.result = VotingResult;

            await post('exitVoting/submitVote', params).then((data) => {
                if (data.message === 'Success') {
                    sessionStorage.setItem('recId', '');
                    setLoading(false);
                    goDetail('/home');

                }
                else {
                    setConfirmButtonStatus(false);
                    setLoading(false);
                }
            });
        }
        else {
            setConfirmButtonStatus(false);
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? <Loading /> : null}
            <div className='stepper-content' >
                <div id="exit-summary-div">
                    <div className='row stepper-title'>
                        <p>
                            Summary
                        </p>
                    </div>
                    <div className='row stepper-p exit-summary-content'>
                        <div>
                            <table className='summary-table'>
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('US Tax Residency')}:
                                        </td>
                                        <td>
                                            {VotingDetails.usResident ? "Resident" : "Non-Resident"}
                                        </td>
                                    </tr>
                                    {
                                        VotingDetails.usResident ? (<>
                                            <tr>
                                                <td>
                                                    {t('State')}:
                                                </td>
                                                <td>
                                                    {VotingDetails.usStateName === '' ? '-' : VotingDetails.usStateName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('US Citizen')}:
                                                </td>
                                                <td>
                                                    {VotingDetails.citizenship === 'United States' ? "Yes" : "No"}
                                                </td>
                                            </tr>
                                        </>) : ""
                                    }
                                </tbody>
                            </table>
                            <div className='offer-summary'>
                                <p className='stepper-title'>
                                    {t('Selected votes')}
                                </p>
                                <table className='ballot-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('Offer')}</th>
                                            <th>{t('Answer')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            VotingResult.length !== 0 ? (
                                                VotingResult.map((result) => (<>
                                                    <tr>
                                                        <td>{result.optionSequence}</td>
                                                        <td>{result.offer}</td>
                                                        <td>
                                                            {
                                                                result.yesVoteUnit > 0 ? "Yes" : result.noVoteUnit > 0 ? "No" : "Manual"
                                                            }
                                                        </td>
                                                    </tr>
                                                </>))
                                            ) : (<>
                                                <tr>
                                                    <td colSpan={3}>
                                                        No selected votes.
                                                    </td>
                                                </tr>
                                            </>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ lineHeight: "1" }}>
                                <p className='signature-title'>
                                    {t('Signature')}
                                </p>
                                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    {t('Note_Signature')}
                                </p>
                            </div>
                            <div className='signature-container'>
                                <div className='signature-div'>
                                    <SignatureCanvas penColor='black' ref={signatureCanvasRef}
                                        canvasProps={{ className: 'sigCanvas' }} onEnd={handleEnd} />
                                </div>
                                <div className='clear-button-div'>
                                    <button className='btn ' onClick={handleClear}>{t('Clear')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='stepper-footer' style={{ marginTop: "-100px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleConfirm} disabled={confirmButtonStatus}>{t('Confirm and Submit')}</Button>
                </Box>
            </div>
        </>
    );
}