import axios from 'axios';
import alertMsg from './alertMsg';
import { routerRefFunc } from './routerRef';
import { useHistory } from 'react-router'; 
import { ErrorHandle } from '../components/common/errorDialog';
import store from '../redux/store';



const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEPATH, //dev
  timeout: 60000,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    console.log(config);
    // Do something before request is sent
    // config.headers['auth']='gettoken'
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);



const confirm = () => {
  routerRefFunc.pushSkipUrl('/');
  sessionStorage.removeItem('session');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('token');
};
// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.code !== undefined && response.data.code !== 0) {
      switch (response.data.code) {
        case 2005:
          // alertMsg({ title: response.data.message, confirm, cancle: confirm });
          ErrorHandle(response.data.code);
          break;
        case 2007:
          // alertMsg({ title: response.data.message, confirm, cancle: confirm });
          ErrorHandle(response.data.code);
          break;
        case 50212:
          // alertMsg({ title: response.data.message, confirmText: 'Try Again' });
          ErrorHandle(response.data.code);
          break;
        case 1001:
          ErrorHandle(response.data.code);
          break;
        case 2003:
          // alertMsg({ title: response.data.message });
          ErrorHandle(response.data.code);
          break;
        default:
          // alertMsg({ title: response.data.message });
          ErrorHandle(response.data.code);
          break;
      }
      return null;
    }
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

let setFormData = function (data) {
  let params = new FormData();
  for (let key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      params.append(key, data[key]);
    }
  }
  return params;
};

export function get(url, params) {
  let header = {
    userId: params.userId,
    accessToken: params.accessToken,
    // accessToken: "c89960648ae6224f2a45f055e1f7c3ee3e7b75024a67356825ed64d412a1b797",
    // userId: "61",
  };

  delete params['userId'];
  delete params['accessToken'];
  return instance.get(url, { params: params, headers: header });
}
export function post(url, params) {
  let header = {
    userId: params.userId,
    accessToken: params.accessToken,
    // accessToken: "c89960648ae6224f2a45f055e1f7c3ee3e7b75024a67356825ed64d412a1b797",
    // userId: "61",
  };
  delete params['userId'];
  delete params['accessToken'];
  return instance.post(url, params, { headers: header });
}

export function upload(url, params) {
  let header = {
    userId: params.userId,
    accessToken: params.accessToken,
    // accessToken: "c89960648ae6224f2a45f055e1f7c3ee3e7b75024a67356825ed64d412a1b797",
    // userId: "61",
  };
  delete params['userId'];
  delete params['accessToken'];
  return instance.post(url, setFormData(params), { headers: header });
}

export function getImage(url, params) {
  let header = {
    userId: params.userId,
    accessToken: params.accessToken,
    // accessToken: "c89960648ae6224f2a45f055e1f7c3ee3e7b75024a67356825ed64d412a1b797",
    // userId: "61",
  };
  delete params['userId'];
  delete params['accessToken'];

  return instance.get(url, { params: params, headers: header, responseType: 'blob' });
}

export function login(url, params) {
  return instance.post(url, params);
}
export function put(url, params) {
  let header = {
    userId: params.userId,
    accessToken: params.accessToken,
    // accessToken: "c89960648ae6224f2a45f055e1f7c3ee3e7b75024a67356825ed64d412a1b797",
    // userId: "61",
  };
  delete params['userId'];
  delete params['accessToken'];
  return instance.put(url, params, { headers: header });
}
// export function deleteCall(url,params){
//   axios.delete(url,params)
// }
