import { React, useEffect } from 'react';
import { useState } from 'react';
import { post } from '../../util/request';
import { get } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { DatePickerField } from '../../components/common/form';
import { useTranslation } from 'react-i18next';
import './index.scss'
import forge from 'node-forge';



const ActivateAccount = () => {

  const history = useHistory();
  const { t } = useTranslation();

  const [isButton, setButton] = useState('')


  const [ischeck, setcheck] = useState(true)
  const Client = () => {
    empty()
    if (ischeck === false) {
      setcheck(!ischeck)
    }
  }
  const Other = () => {
    empty()
    if (ischeck === true) {
      setcheck(!ischeck)
    }
  }


  const [key, setkey] = useState('')




  const empty = () => {
    setdateofBirth(null)
    setlastName('')
    setemail('')
    setusername('')
  }


  const [dateofBirth, setdateofBirth] = useState(null)
  const dateofBirthChange = event => {
    setdateofBirth(event.target.value);
  };

  const [lastName, setlastName] = useState('')
  const lastNameChange = event => {
    setlastName(event.target.value);
  };





  const [email, setemail] = useState('')
  const emailChange = event => {
    setemail(event.target.value);
  };

  const [username, setusername] = useState('')
  const usernameChange = event => {
    setusername(event.target.value);
  };


  const [clientID, setclientID] = useState('')
  const clientIDChange = event => {
    setclientID(event.target.value);
  };







  useEffect(() => {
    setButton('');
    getkey()
  }, []);



  const setup = async () => {
    if (!email) {
      alert('Pls input email')
      return
    }
    if (!lastName && ischeck) {
      alert('Pls input lastName')
      return
    }
    if (!dateofBirth && ischeck) {
      alert('Pls input dateofBirth')
      return
    }
    if (!clientID && !ischeck) {
      alert('Pls input dateofBirth')
      return
    }
    var pubencryptKey = key

    let rsaPublicKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----${pubencryptKey}-----END PUBLIC KEY-----`);
    let encrypted = rsaPublicKey.encrypt(clientID, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    let setupdata = {
      clientType: ischeck ? "CLIENT" : "BIZ",
      dob: dateofBirth,
      clientID: forge.util.encode64(encrypted),
      email: email,
      lastName: lastName,
      deviceModel: "WEB",
      entry: "WEB",
      isChina: false,
      username: username,
      deviceId: "",
      deviceToken: "",
    }
    if (dateofBirth) {
      const year = dateofBirth.getFullYear();
      const month = String(dateofBirth.getMonth() + 1).padStart(2, '0'); // 补零至两位数
      const day = String(dateofBirth.getDate()).padStart(2, '0');
      const formattedDate = `${year}/${month}/${day}`;
      setupdata.dob = formattedDate;
    }
    const data = await post('/auth/username/forgot', setupdata);
    if (!data) {
      setdateofBirth(null)
      setlastName('')
      setemail('')
      setusername('')
      return
    }
    if (data.code === 0) {
      setButton('success')
    } else {

      setButton('fail')
    }

  };


  const getkey = async () => {
    let deviceId = {
      deviceId: 'Web'
    }
    const data = await get('/auth/key', deviceId);
    if (!data) {
      return
    }
    setkey(data.message)
    // encryptPassword(data.message, id)
  };
  const backtoHome = () => {
    history.push('/')
  }
  const gotoContanct = () => {
    history.push('/contactUs')
  }

  const handleSetup = () => {

    if (isButton == 'success') {
      return (
        <div className='content d-flex flex-column justify-content-center align-items-center'>

          <div className='m-b-32'>
            <img src={'./Combined Shape.png'} width="81" height="80" viewBox="0 0 81 80" fill="none" />

          </div>

          <div className='promote m-b-32'>{t('An email with your username information has been sent to ')} {email}</div>


          <div className=''>
            <button onClick={() => backtoHome()} type="button" class="nextBtn btn btn-outline-next">{t('Login')} </button>
          </div>

        </div>
      )
    } else if (isButton == 'fail') {
      return (
        <div className='content d-flex flex-column justify-content-center align-items-center'>

          <div className='m-b-32'>
            <img src={'./Group.png'} width="81" height="80" viewBox="0 0 81 80" fill="none" />
          </div>

          <div className='promote m-b-32'>{t('Your account information is not correct')}</div>


          <div className=''>
            <button onClick={() => backtoHome()} type="button" class="nextBtn btn btn-outline-next">{t('Try Again')}</button>
            <button type="submit" className="btn btn-outline-theme col-sm-3" style={{ width: '100%' }}>{t('Contact Client Services')}</button>
          </div>

        </div>
      )
    } else {
      return (
        <div className='content '>
          <div className='promote m-b-32 '>{t('Please enter the following information')}</div>

          <div>
            <div className='usertype'>
              <p>{t('User Type*')}</p>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center'>
                  <button className={(ischeck ? 'radio radio-check' : 'radio')} onClick={() => Client()}>
                    <div className='check '></div>
                  </button>
                  {t('Client')}
                </div>

                <div className='d-flex align-items-center'>
                  <button className={(!ischeck ? 'radio radio-check' : 'radio')} onClick={() => Other()}>
                    <div className='check'></div>
                  </button>
                  {t('Business Partner')}
                </div>
              </div>
            </div>
            <div className='clientid'>
              {!ischeck ? (<><span>{t('Vendor ID')}*</span>
                <input value={clientID} type="text" onChange={clientIDChange} placeholder={t('Vendor ID')} className='clientid-input' /></>) : null}
              <span>{t('Registered Email Address*')}</span>
              <input value={email} onChange={emailChange} type="text" placeholder={t('Enter registered email address')} className='clientid-input' />
              {ischeck ? <>
                <span>{t('Last Name*')}</span>
                <input value={lastName} onChange={lastNameChange} type="text" placeholder={t('Enter your last name')} className='clientid-input' />
                <span>{t('Date of Birth*')}</span>
                <div className='datainput'>
                  <DatePickerField value={dateofBirth} setValue={setdateofBirth}></DatePickerField>
                </div>
              </> : null}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <button onClick={() => setup()} type="button" class="nextBtn btn btn-outline-next">{t('NEXT')}</button>
            <div className='promote' style={{ color: '#5582a0' }}>
              {t('Having problems?')}
            </div>

            <button onClick={() => gotoContanct()} type="button" class="btn btn-outline-link">{t('Contact Client Services')}</button>
            <button onClick={() => backtoHome()} type="button" class="btn btn-outline-link">{t('Back home')}</button>
          </div>

        </div>
      )
    }

  }






  return (
    <div className='activateAccount'>
      <div className='d-flex justify-content-between align-items-center headline-box'>
        <div className='line'>
        </div>
        <h1 className='headline'>

          {isButton == 'success' ? t('Forgot Username') : <></>}
          {isButton == 'fail' ? t('Invalid information') : <></>}
          {isButton == '' ? t('Forgot Username') : <></>}
        </h1>
        <div className='line'>
        </div>
      </div>
      <div className='main-box'>
        <div className='content-box'>
          {/* <div className='content'>
              <div className='promote m-b-32'>Please enter the following information and the email address that you used to register.We'll send you an email with your username</div>

                <div>
                  <div className='usertype'>
                <p>User type*</p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <button className={(ischeck ? 'radio radio-check' : 'radio')} onClick={() => Client()}>
                      <div className='check '></div>
                    </button>
                    Email
                  </div>

                  <div className='d-flex align-items-center'>
                    <button className={(!ischeck ? 'radio radio-check' : 'radio')} onClick={() => Other()}>
                      <div className='check'></div>
                    </button>
                    Business Partner
                  </div>
                </div>
              </div>
                    <div className='clientid'>
                      <span>Registered Email Address</span>
                      <input value={email} onChange={emailChange} type="text" placeholder="Enter email" className='clientid-input' />
                      <span>Last Name</span>
                      <input value={lastName} onChange={lastNameChange} type="text" placeholder="Enter Last Name" className='clientid-input' />
                      <span>Date of Birth</span>
                      <div className='datainput'>
                        <DatePickerField value={dateofBirth} setValue={setdateofBirth}></DatePickerField>
                      </div>
                    </div> 
                </div>
              <div className='d-flex flex-column justify-content-center'>
                <button onClick={() => setup()} type="button" class="nextBtn btn btn-outline-next">NEXT</button>
                <button onClick={() => backtoHome()} type="button" class="btn btn-outline-link">Back Home</button>
              </div>

            </div> */}
          {handleSetup()}
        </div>

      </div>
    </div>
  );
};
export default ActivateAccount;
