import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const Loading = () => {
    return (
        <div className='loading-box'>
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 48,
                        }}
                        spin
                    />
                }
            />
        </div>
    )
}
export default Loading