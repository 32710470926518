import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {},
    error:  sessionStorage.getItem('error') ? JSON.parse(sessionStorage.getItem('error')) : {},
  },
  reducers: {
    updateError: (state, action) => {
      state.error = action.payload
      sessionStorage.setItem('error', JSON.stringify(action.payload))
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload
      sessionStorage.setItem('userInfo', JSON.stringify(action.payload))
    },
    updateUserInfoFun: (state, action) => {
      state.userInfo.systemFuncs = action.payload.systemFuncs;
      state.userInfo.systemActions = action.payload.systemActions;
      state.userInfo.systemUserRole = action.payload.systemUserRole;
      sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
  },
})

export const { updateError,updateUserInfo, updateUserInfoFun } = counterSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const userInfo = (state) => state.counter.userInfo
export const error = (state) => state.counter.error

export default counterSlice.reducer
