import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import './index.scss'
import { get, post } from '../../util/request';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { Image } from '../../assets';
const baseurl = process.env.REACT_APP_BASEPATH;
const Landing = () => {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.resolvedLanguage);
  const [isModalOpen, setIsModalOpen] = useState(true);

  // async function handleLogin(){
  //   debugger
  //   const obj = {
  //     claims:'emailAddress'
  //   }
  //   const data = await post('/auth/aad/b2c/login/page/web', obj);
  // }

  function goDetail(url) {
    console.log(url)
    history.push({
      pathname: url,
    });
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (i18n.resolvedLanguage == 'en_US') {
      setLang('en_US')
    } else if (i18n.resolvedLanguage == 'zh_HK') {
      setLang('zh-HK')
    } else if (i18n.resolvedLanguage == 'jp') {
      setLang('ja')
    } else if (i18n.resolvedLanguage == 'zh_CN') {
      setLang('zh_CN')
    }
  }, [i18n.resolvedLanguage])
  return (
    <>
      <div className="landing-modal-content">
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleOk}
          footer=""
          className="landing-modal-box"
        >
          <div className="landing-modal">
            <div className="image-box">
              <img src={Image.landingPopUp} alt="pop up" />
            </div>
            <div className="custom-modal-body">
              <button
                type="button"
                class="nextBtn"
                onClick={() => {
                  handleOk();
                }}
              >
                {t('Done')}
              </button>
            </div>
          </div>

        </Modal>
      </div>
      <div className='landing'>
        <div className='page-content '>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='line'>
            </div>
            {/* <p>Investments</p> */}
            <div className='headline'>
              {t('Landing')}
            </div>
            <div className='line'>
            </div>
          </div>
          <div className='main-box'>
            <div className='content-desc' style={{ height: '70px' }}>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and</p>
            <p>typesetting industry. Lorem Ipsum has been the industry's</p>
            <p>standard dummy text ever since the 1500s</p> */}
            </div>

            <div className='content-button'>
              <form action={baseurl + "/auth/aad/b2c/login/page/web?lang=" + lang} method="post">
                <input type="hidden" name="claims" value="emailAddress" />
                <button type="submit" className="btn btn-outline-theme col-sm-3" >{t('Login')}</button>
              </form>
            </div>
            <div className='content-button'>
              <button onClick={() => { goDetail('/activateAccount') }} type="button" className="btn btn-outline-theme col-sm-3" >{t('Account Activation')}</button>
            </div>
            <text className='content-forget' onClick={() => { goDetail('/forget') }}>
              {t('Forgot Username')}
            </text>
          </div>

        </div>
      </div >
    </>
  );
};
export default Landing;
