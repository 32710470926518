import React, { useState } from 'react';
import Select from 'react-select'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';


const SortTypeSelect = ({ value, setValue, sortOption }) => {
  // const sortOption = [
  //   { value: 'desc', label: 'Creation date (New - Old)'},
  //   {value: 'asc', label: 'Creation date (Old - New)'}
  // ]
  const headleSortType = (e) => {
    setValue(e)
  }
  return (
    <div>
      <Select
        isSearchable={false}
        options={sortOption}
        placeholder={'All'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: '#F5F7FA',
            primary: '#003448',
          },
        })}
        value={value}
        onChange={headleSortType}
      />
    </div>
  );
};

const DatePickerField = ({ value, setValue }) => {
  const { t } = useTranslation();
  return (
    <div>
      <DatePicker className="form-control" showIcon isClearable selected={value} onChange={(date) => {
        console.log(date);
        setValue(date)
      }} dateFormat="yyyy/MM/dd" placeholderText={t('YYYY / MM / DD')} />
    </div>
  );
};

const SearchDatePickerField = ({ value, setValue }) => {

  return (
    <div>
      <DatePicker className="form-control" showIcon isClearable selected={value} onChange={(date) => setValue(date)} dateFormat="yyyy/MM/dd" />
    </div>
  );
};

export {
  SortTypeSelect,
  DatePickerField,
  SearchDatePickerField
};