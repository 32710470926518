import { forEach } from 'lodash';
import './selected-filters.scss'
import React, { useEffect, useState} from 'react';

const SelectedFilters = ({data,resetFilter,removeFilter}) => {

    const badgeItem = (key, value, id, removeFilter) => {
        if(value == null || value == ''){
            return
        }
        return <span class="badge text-bg-primary py-2 px-3 me-1 rounded-pill" ><button type="button" class="btn-close" aria-label="Close" onClick={() => removeFilter(key,id)} />{value}</span>
    }

    const hasFilter = (show,resetFilter) => {
        if(show){
            return <span class="reset ms-2 me-1 text-decoration-underline" onClick={() => resetFilter()}>Reset</span>
        }
    
        return
    }
    

    let _hasFilter = false
    
    forEach(Object.entries(data),(item)=>{
        if(item[1] != null && item[1] != ''){
            _hasFilter = true
        }
    })

    return (
        <div>
            {Object.entries(data).map((item) =>{
                if(item[1] == null){
                    return
                }
                return Object.entries(item[1]).map((lbl) =>{
                    return badgeItem(item[0],lbl[1].label,lbl[1].value,removeFilter)
                })
            })}
            {hasFilter(_hasFilter,resetFilter)}
        </div>
    )
}
export default SelectedFilters